<template>
  <div
    class="not-bg-found not-h-100vh success">
    <div
      class="m-center">
      <div
        class="is-hidden-desktop"
        style="text-align: center;">
        <img
          style="max-height: 211px;"
          src="@/assets/images/booking/img-not-booking-ok-mobile.svg"
          alt="imagen-success">
      </div>
      <div
        class="is-flex is-justify-content-center-mobile is-align-items-center">
        <div class=" is-hidden-touch">
          <img style="height: 100vh;" src="@/assets/images/booking/img-not-booking-ok.svg" alt="imagen-success">
        </div>
        <div
          class="container-notBooking-desk">
          <div
            class="container is-max-desktop not-w-content pb-2">
            <h3
              class="title-regular is-hidden-desktop has-text-white-ter text-primary mt-3 pt-3 has-text-weight-normal has-text-centered text-basier">
              {{titleSuccess}}
            </h3>
            <h3
              class="title-regular is-hidden-touch has-text-white-ter text-primary mt-3 pt-3 has-text-weight-normal has-text-centered text-basier">
              {{titleSuccess}}
            </h3>
            <p class="paragraph-content not-w-content has-text-grey-lighter mt-4 pt-3 text-secondary has-text-weight-light has-text-centered text-nunito line-h-content">
                Se hará un cargo a tu tarjeta cuando {{name}} confirme tu reserva. Una vez confirmado te enviaremos un link para la videollamada.
            </p>
          </div>
          <div
            v-if="reschedule"
            class="is-flex is-flex-direction-column is-align-items-center"
            style="width: 204px; margin: 21px auto 40px auto; color: #ffffff;"
          >
            <div>
              <p
                class="text-basier-bold mb-4"
                style="color: #ffffff">
                Reserva Cancelada
              </p>
              <div
                class="is-flex mb-2">
                <!-- <img class="mr-2" src="https://emituscdn.com/calendar-vector.svg" alt="" width="14px"> -->
                <span
                  style="text-decoration: line-through">
                  {{currentBooking.date}}
                </span>
              </div>
              <div
                class="is-flex">
                <!-- <img class="mr-2" src="https://emituscdn.com/clock-vector.svg" alt="" width="15px"> -->
                <span
                  style="text-decoration: line-through">
                  {{currentBooking.time}}
                </span>
              </div>
            </div>

            <div
              class="mt-5 mb-6">
              <p
                class="text-basier-bold mb-4"
                style="color: #ffffff">
                Nueva reserva
              </p>
              <p
                class="mb-2">
                <span
                  class="text-nunito-SemiBold">
                  Date:
                </span>
                {{newDurationInit}} - {{newDurationEnd}}
              </p>
              <p
                class="mb-2">
                <span
                  class="text-nunito-SemiBold">
                  Hola:
                </span>
                {{newDate}}
              </p>
              <p
                class="mb-2">
                <span
                  class="text-nunito-SemiBold">
                  Pagado:
                  </span>
                  {{price}} {{currentBooking.currency === 'USD' ? '$' : '€'}}
              </p>
              <p
                class="mb-2">
                <span
                  class="text-nunito-SemiBold">
                  Idioma:
                </span>
                {{currentBooking.language}}
              </p>
            </div>
          </div>

          <div
            class="buttons is-flex is-flex-direction-column not-w-btn container is-max-desktop mt-5">
            <b-button
              @click="finishBooking()"
              class="text-basier-medium button-primary-big"
              expanded>
              Aceptar
            </b-button>
          </div >
          <div
            class="is-max-desktop container has-text-centered mt-5 pb-6">
            <a
              href="https://api.whatsapp.com/send?phone='+34643943764'&text=hola,%20me%20gustaria%20saber%20mas%20sobre%20Abogadus"
              target="_blank"
              class="link-text-purple purple-extra-ligth text-nunito">
              ¿Necesitas ayuda? Haz click aquí.
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import dayjs from 'dayjs'
export default {
  name: 'Success',
  props: {
    name: String,
    titleSuccess: String,
    startBook: Date,
    endBook: Date,
    currentBooking: Object,
    reschedule: Boolean
  },
  data () {
    return {
      newDate: new Date(),
      newDurationInit: null,
      newDurationEnd: null
    }
  },
  created () {
    this.newDate = dayjs(this.startBook).format('D MMMM YY')
    this.newDurationInit = dayjs(this.startBook).format('HH:mm')
    const endDuration = dayjs(this.endBook).toDate()
    this.newDurationEnd = dayjs(endDuration).format('HH:mm')
  },
  methods: {
    finishBooking () {
      this.notify.$emit('close')
      this.$router.push('/panel/booking')
    }
  }
}
</script>

<style scoped>
.success {
    top: 0;
    left: 0;
    position: fixed;
    width: 100%;
    z-index: 9;
    overflow: auto;
}
.not-h-100vh {
    height: 100vh;
}
.not-bg-found {
    background-color: #0d1239;
}
.not-w-content {
    width: 334px;
}
.not-w-btn {
    width: 210px;
}
@media (min-width: 768px) {
  .container-notBooking-desk {
    width: 55%;
    /* margin-top: 80px; */
  }
  .container-notBooking-desk h3 {
    margin-bottom: 50px;
  }
  .container-notBooking-desk .buttons {
    margin-top: 50px;
  }
}

@media (max-width: 1024px) {
  .is-justify-content-center-mobile {
    justify-content: center;
  }
  .container-notBooking-desk .buttons {
    margin-top: 20px;
  }
}
</style>
