<template>
  <div  class="container-booking mt-5">

    <!-- Title -->
    <div class="ml-3">
      <p class="title-regular text-basier has-text-left title-regular dark-blue-emitus mb-2">
        Fecha & hora
      </p>
      <p class="description-nunito dark-gray-emitus has-text-left mb-6">
        Cuándo va a ser la consulta
      </p>
    </div>

    <!-- Calendar -->
    <div
      class="border-bottom is-flex has-text-centered m-center is-justify-content-center"
    >
      <span>
        <a @click="prevWeek" id="prevWeekCalendar" class="prev-next icon disabled">
          <img src="@/assets/images/booking/icon-prev.svg" alt="prev">
        </a>
      </span>
      <div
        style="display: flex; padding-bottom: 14px"
        class="container-days-booking is-flex-direction-column"
      >
        <div
          class=""
          style="display: flex; justify-content: center; flex-direction: column"
          >
          <!-- dias en letras -->
          <ul
            style="
              color: #0d1239;
              list-style: none;
              display: flex;
            "
            class="weekdays is-justify-content-space-between text-basier"
          >
            <li
              v-for="(weekDay, idx) in weekDays"
              :key="idx"
              class="text-basier dark-blue-emitus"
              style="font-size: 11px; margin-left: 5px; margin-right: 5px; width: 25px!important;">
              {{weekDay}}
            </li>
          </ul>

          <!-- muestra el mes -->
          <div class="month text-basier my-1 ml-2">
            <ul style="list-style: none; display: flex; width: 100%; justify-content: space-between;">
              <li
                v-for="(date, idx) in weekDates"
                :key="idx"
              >
                {{ date.month }}
              </li>
            </ul>
          </div>

          <!-- dias en numeros -->
          <ul style="list-style: none; display: flex; width: 100%; justify-content: space-between;">
            <li
              class="day_number text-basier dark-blue-emitus"
              v-for="(date, idx) in weekDates"
              :key="idx"
              @click="showSlotsForDate(idx)"
              :id="'date' + idx"
            >
              {{ date.date }}
            </li>
          </ul>
        </div>
      </div>
      <span class="icon-text">
        <a @click="nextWeek" class="prev-next icon">
          <img src="@/assets/images/booking/icon-next.svg" alt="next">
        </a>
      </span>
    </div>

    <!-- Timezone-->

    <!-- Listing Slots -->
    <div>
      <div
        v-for="(slot, idx) in currentSlotsFormated"
        :key="idx"
        class="slot is-flex"
      >
      <div class="pl-3 infoSlot is-flex is-flex-direction-column is-justify-content-center">
        <p class="text-basier dark-blue-emitus hour-available-booking">
          {{slot.startSlotF}} - {{slot.endSlotF}}
        </p>
        <p class="mt-1 price-and-duration-booking text-nunito">
          {{price}}{{currency === 'USD' ? '$' : '€'}} / {{duration}}min
        </p>
      </div>
      <div class="buttonSlot is-flex is-align-items-center">
        <button
          @click="slotSelected(slot.startSlot, slot.endSlot)"
          class="button-secundary-small"
        >
          <span
            class="text-basier-medium">
            Reserva </span>
        </button>
      </div>

      </div>
    </div>

  </div>
</template>

<script>
import dayjs from 'dayjs'
import CalendarService from '@/services/CalendarService'

var utc = require('dayjs/plugin/utc')
var timezone = require('dayjs/plugin/timezone')
var toObject = require('dayjs/plugin/toObject')
var localizedFormat = require('dayjs/plugin/localizedFormat')
const isBetween = require('dayjs/plugin/isBetween')
const isSameOrAfter = require('dayjs/plugin/isSameOrAfter')

dayjs.extend(isSameOrAfter)
dayjs.extend(isBetween)
dayjs.extend(toObject)
dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(localizedFormat)

export default {
  name: 'dateTime',
  props: {
    currency: String
  },
  data () {
    return {
      // OK
      weeklyBlocks: null,
      timezone: null,
      week: 0,
      professionalId: null,
      duration: null,
      price: null,

      // Testing
      testdate: null,

      // Slots to be shown for each day and week
      slotsMonday: [],
      slotsTuesday: [],
      slotsWednesday: [],
      slotsThursday: [],
      slotsFriday: [],
      slotsSaturday: [],
      slotsSunday: [],

      // Current Slots
      currentSlots: [],

      // Reder Calendar Data
      weekDays: ['M', 'T', 'W', 'T', 'F', 'S', 'S'],
      weekDates: []
    }
  },
  async created () {
    // Get ProfileId
    await this.notify.$on('sendProfessionalId', professionalId => {
      this.professionalId = professionalId
    })
    this.notify.$emit('giveMeProfessionalId')

    // Get Duration
    await this.notify.$on('sendDuration', duration => {
      this.duration = duration
    })
    this.notify.$emit('giveMeDuration')

    // Get Price
    await this.notify.$on('sendPrice', price => {
      this.price = price
    })
    this.notify.$emit('giveMePrice')

    // Get Blocks By Week
    await CalendarService.getBlocksByWeek(
      this.professionalId,
      this.week
    )
      .then(blocks => {
        this.weeklyBlocks = blocks.data
        setTimeout(() => {
          let firstDayWithSlots = parseInt(dayjs(this.slots[0].startSlot).format('d'), 10)
          if (firstDayWithSlots === 0) { firstDayWithSlots = 7 }
          this.showSlotsForDate(firstDayWithSlots - 1)
        }, 1000)
      })
  },
  mounted () {
    // Get Timezone
    this.timezone = dayjs.tz.guess()
  },
  watch: {
    week: async function () {
    // Get Blocks By Week
      await CalendarService.getBlocksByWeek(
        this.professionalId,
        this.week
      )
        .then(blocks => {
          this.weeklyBlocks = blocks.data
        })
    },
    slots: async function () {
      if (this.slots) {
        this.slotsMonday = this.slots.filter(slot => dayjs(slot.startSlot).add(1, 'minute').isBetween(this.monday, this.tuesday))
        this.slotsTuesday = this.slots.filter(slot => dayjs(slot.startSlot).add(1, 'minute').isBetween(this.tuesday, this.wednesday))
        this.slotsWednesday = this.slots.filter(slot => dayjs(slot.startSlot).add(1, 'minute').isBetween(this.wednesday, this.thursday))
        this.slotsThursday = this.slots.filter(slot => dayjs(slot.startSlot).add(1, 'minute').isBetween(this.thursday, this.friday))
        this.slotsFriday = this.slots.filter(slot => dayjs(slot.startSlot).add(1, 'minute').isBetween(this.friday, this.saturday))
        this.slotsSaturday = this.slots.filter(slot => dayjs(slot.startSlot).add(1, 'minute').isBetween(this.saturday, this.sunday))
        this.slotsSunday = this.slots.filter(slot => dayjs(slot.startSlot).add(1, 'minute').isSameOrAfter(this.sunday))
        this.weekDates = [
          { date: dayjs(this.monday).format('D'), month: dayjs(this.monday).format('MMM') },
          { date: dayjs(this.tuesday).format('D'), month: dayjs(this.tuesday).format('MMM') },
          { date: dayjs(this.wednesday).format('D'), month: dayjs(this.wednesday).format('MMM') },
          { date: dayjs(this.thursday).format('D'), month: dayjs(this.thursday).format('MMM') },
          { date: dayjs(this.friday).format('D'), month: dayjs(this.friday).format('MMM') },
          { date: dayjs(this.saturday).format('D'), month: dayjs(this.saturday).format('MMM') },
          { date: dayjs(this.sunday).format('D'), month: dayjs(this.sunday).format('MMM') }
        ]
      }
    },
    currentSlots: async function () {
      // Monday
      if (this.currentSlots === this.slotsMonday) {
        document.getElementById('date0').classList.add('black')
      } else {
        document.getElementById('date0').classList.remove('black')
      }
      // Tuesday
      if (this.currentSlots === this.slotsTuesday) {
        document.getElementById('date1').classList.add('black')
      } else {
        document.getElementById('date1').classList.remove('black')
      }
      // Wednesday
      if (this.currentSlots === this.slotsWednesday) {
        document.getElementById('date2').classList.add('black')
      } else {
        document.getElementById('date2').classList.remove('black')
      }
      // Thrusday
      if (this.currentSlots === this.slotsThursday) {
        document.getElementById('date3').classList.add('black')
      } else {
        document.getElementById('date3').classList.remove('black')
      }
      // Friday
      if (this.currentSlots === this.slotsFriday) {
        document.getElementById('date4').classList.add('black')
      } else {
        document.getElementById('date4').classList.remove('black')
      }
      // Saturday
      if (this.currentSlots === this.slotsSaturday) {
        document.getElementById('date5').classList.add('black')
      } else {
        document.getElementById('date5').classList.remove('black')
      }
      // Sunday
      if (this.currentSlots === this.slotsSunday) {
        document.getElementById('date6').classList.add('black')
      } else {
        document.getElementById('date6').classList.remove('black')
      }
    }
  },
  computed: {

    // Today
    today: function () {
      const today = new Date()
      return today
    },

    // dayOfWeek
    dayOfWeek: function () {
      const today = new Date()
      const day = today.getDay()
      if (day === 0) {
        return 7
      } else {
        return day
      }
    },

    // Slots of time for each Week
    slots: function () {
      const slots = []
      // Build all posible slots of the week
      if (this.weeklyBlocks) {
        for (let i = 0; i < this.weeklyBlocks.length; i++) {
          const startBlock = dayjs(this.weeklyBlocks[i].start)
          const endBlock = dayjs(this.weeklyBlocks[i].end)
          const numberOfSlotBlocks = Math.floor(endBlock.diff(startBlock, 'minute') / (this.duration))
          // const numberOfSlotBlocks = Math.floor(endBlock.diff(startBlock, 'minute') / (this.duration - 1))
          // console.log('--> aja', endBlock.diff(startBlock, 'minute'), numberOfSlotBlocks, this.duration)
          for (let j = 0; j < numberOfSlotBlocks; j++) {
            const startSlot = dayjs(startBlock).add(this.duration * j, 'minute')
            const endSlot = dayjs(startBlock).add(this.duration * (j + 1), 'minute')
            const slot = {
              startSlot: startSlot,
              endSlot: endSlot
            }
            slots.push(slot)
          }
        }
        // Only Future Slots
        const todayJS = dayjs(this.today)
        const futureSlots = slots.filter(slot => slot.startSlot > todayJS)
        return futureSlots
      } else {
        return slots
      }
    },

    // Monday, Tuesday, ...

    monday: function () {
      const today = new Date()
      const lastMonday = today.setDate(today.getDate() - (this.dayOfWeek - 1))
      const lastMondayS = dayjs(lastMonday).set('hour', 0).set('minute', 0).set('second', 0) // Start
      const monday = dayjs(lastMondayS).add((7 * this.week), 'day')
      return monday
    },
    tuesday: function () {
      const tuesday = dayjs(this.monday.add(24, 'hour'))
      return tuesday
    },
    wednesday: function () {
      const tuesday = dayjs(this.monday.add(24 * 2, 'hour'))
      return tuesday
    },
    thursday: function () {
      const tuesday = dayjs(this.monday.add(24 * 3, 'hour'))
      return tuesday
    },
    friday: function () {
      const tuesday = dayjs(this.monday.add(24 * 4, 'hour'))
      return tuesday
    },
    saturday: function () {
      const tuesday = dayjs(this.monday.add(24 * 5, 'hour'))
      return tuesday
    },
    sunday: function () {
      const tuesday = dayjs(this.monday.add(24 * 6, 'hour'))
      return tuesday
    },

    // Format slots
    currentSlotsFormated: function () {
      const currentSlotsFormated = []
      for (let i = 0; i < this.currentSlots.length; i++) {
        const startSlot = dayjs(this.currentSlots[i].startSlot).toDate()
        const endSlot = dayjs(this.currentSlots[i].endSlot).toDate()
        const startSlotF = dayjs(this.currentSlots[i].startSlot).format('HH:mm')
        const endSlotF = dayjs(this.currentSlots[i].endSlot).format('HH:mm')
        const obj = {
          startSlot: startSlot,
          endSlot: endSlot,
          startSlotF: startSlotF,
          endSlotF: endSlotF
        }
        currentSlotsFormated.push(obj)
      }
      return currentSlotsFormated
    }
  },
  methods: {
    // Send data to be sent by booking component
    slotSelected (start, end) {
      this.notify.$emit('slotSelected', {
        start: start,
        end: end
      })
    },

    // Show slots for each day
    showSlotsForDate (idx) {
      if (idx === 0) {
        this.currentSlots = this.slotsMonday
      } else if (idx === 1) {
        this.currentSlots = this.slotsTuesday
      } else if (idx === 2) {
        this.currentSlots = this.slotsWednesday
      } else if (idx === 3) {
        this.currentSlots = this.slotsThursday
      } else if (idx === 4) {
        this.currentSlots = this.slotsFriday
      } else if (idx === 5) {
        this.currentSlots = this.slotsSaturday
      } else if (idx === 6) {
        this.currentSlots = this.slotsSunday
      }
    },

    // Change Week
    prevWeek () {
      this.week--
      if (this.week <= 0) {
        document.getElementById('prevWeekCalendar').classList.add('disabled')
        setTimeout(() => {
          let firstDayWithSlots = parseInt(dayjs(this.slots[0].startSlot).format('d'), 10)
          if (firstDayWithSlots === 0) { firstDayWithSlots = 7 }
          this.showSlotsForDate(firstDayWithSlots - 1)
        }, 500)
      } else {
        setTimeout(() => {
          this.showSlotsForDate(0)
        }, 500)
      }
    },
    nextWeek () {
      this.week++
      if (this.week > 0) {
        document.getElementById('prevWeekCalendar').classList.remove('disabled')
      }
      setTimeout(() => {
        this.showSlotsForDate(0)
      }, 500)
    }
  }
}

</script>

<style scoped>

.container-days-booking {
  width: 100%;
}

.container-booking {
  width: 90%;
}
.container-calendar-booking {
  width: 94%;
}
.container-days-booking {
  width: 100%;
}
.infoSlot{
  display: inline-block;
  width: 250px;
}
.buttonSlot{
  display: inline-block;
}
.slot {
  border-top: 1px solid #ECECEC;
  padding: 3px;
  margin: 10px;
  height:70px;
  width: 100%;
}
.black {
  font-weight: 700;
  background: #000;
  border-radius: 0;
  color: #fff;
}

.hour-available-booking {
  font-size: 17px;
}

.price-and-duration-booking {
  font-size: 14.5px;
}
.disabled {
  opacity: 0.3;
  cursor: not-allowed!important;
}
.time-zone {
    margin: 0 auto;
    border-top: 1px solid #D5D5D5;
    border-bottom: 1px solid #D5D5D5;
    text-align: -webkit-center;
}
.day_number {
  margin-left: 5px;
  margin-right: 5px;
  width: 25px;
  height: 24px;
  cursor: pointer;
  font-size: 13px;
  padding-top: 3px;
}
.day_number:hover {
  background: #000;
  border-radius: 0;
  color: #fff;
}
.day_number:active {
  background: rgba(41, 41, 41, 0);
}
.day_number:focus {
  background: rgba(41, 41, 41, 0);
  color: #fff;
}
.month {
  text-align: left;
  color: #000;
  font-size: 11px;
}
.select-day {
  background: #000;
  color: #fff;
  border-radius: 100%;
}
</style>
