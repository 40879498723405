<template>
  <div>
    <!-- box to enter as user -->
    <div
      id="join_"
      class="
      join-position-mobile
      join-container-mobile
      join-found-mobile
      join-w-full
      join-h-full
      is-flex
      is-flex-direction-column
      is-justify-content-space-between
      w-100"
    >
      <div
        class="
        w-100
        has-text-right
        btn-skip-table
        pt-5
        is-hidden-tablet">
      </div>
      <div
        class="
        join-bg-transparent
        container-login
        m-center
        border-container
        is-flex
        is-flex-direction-column"
      >
        <div
          id="join_container"
          class="
          container
          is-max-desktop
          not-w-content
          pb-3
          text-color">

          <!-- title: ingresar -->
          <h3
            v-if="showContinue || showLogin"
            class="
            join-display-none
            join-title
            text-basier
            mt-4
            pb-3
            has-text-weight-normal
            has-text-centered
            dark-blue-emitus"
          >
            Ingresar
          </h3>

          <p
            v-if="showContinue || showLogin"
            class="
            join-display-none
            dark-gray-emitus
            mt-2
            paragraph-content
            has-text-centered
            dark-blu-emitus
            paragraph-short"
          >
          </p>

          <!-- title: registrar -->
          <h3
            v-if="showSignup"
            class="
            join-display-none
            join-title
            text-basier
            mt-4
            pb-3
            has-text-weight-normal
            has-text-centered
            dark-blue-emitus"
          >
            Registro
          </h3>

          <p
            v-if="showSignup"
            class="
            join-display-none
            dark-gray-emitus
            mt-2
            paragraph-content
            has-text-centered
            dark-blu-emitus
            paragraph-short"
          >
          </p>

          <div
            class="
            buttons-joins buttons
            align-content-center
            is-flex not-w-btn
            is-flex-direction-column
            container
            is-max-desktop
            z-index-full"
          >

            <!-- enter with google -->
            <b-button
              v-if="showContinue"
              href="#"
              class="
              shadow-btn
              signup-with-button
              big-button
              join-margin-bottom-content-mobile
              has-text-weight-medium
              has-background-white
              has-text-black
              btn-shadow"
              type="is-primary"
              expanded
              size=""
              style="font-size: 13px"
              @click="signInWithGoogle"
            >
              <div
                class="
                is-flex
                is-align-items-center">
                <img
                  class="is-size-7 ml-1"
                  src="@/assets/images/landing/Google.svg"
                  alt=""
                  style="width: 21px;"
                />
                <p
                  class="ml-3">
                  Continúa con Google
                </p>
              </div>
            </b-button>

            <!-- enter with apple -->
            <b-button
              v-if="showContinue"
              href="#"
              class="
                shadow-btn
                signup-with-button
                big-button
                join-margin-bottom-content-mobile
                has-text-weight-medium
                btn-shadow"
              type="is-primary"
              expanded
              size=""
              style="
                font-size: 13px;
                background: #000000 !important;"
              @click="signInWithApple"
            >
              <div
                class="
                  is-flex
                  is-align-self-center">
                <img
                  class="is-size-7"
                  src="@/assets/images/landing/apple.svg"
                  alt=""
                  style="width: 21px;"
                />
                <p
                  class="mt-1 ml-4" style="color: #ffffff">
                  Continue with Apple
                </p>
              </div>
            </b-button>

          <!--- enter with facebook-->
          <b-button
            v-if="showContinue"
            href="#"
            class="
              signup-with-button
              big-button
              join-margin-bottom-content-mobile
              has-text-weight-medium
              btn-shadow"
            type="is-primary"
            expanded
            size=""
            style="
              background: #E1E1E1 !important;
              font-size: 13px;
              align-items: center;"
            @click="showLoginForm"
          >
            <div class="is-flex is-align-self-center">
              <img
                class="ml-3"
                src="https://emituscdn.com/icon-candando.svg"
                alt=""
                style="width: 14px;"
              />
              <span class="mt-1 ml-3 black">Continúa con contraseña</span>
            </div>
          </b-button>

            <!-- <p
              v-if="showContinue"
              class="
              join-margin-bottom-content-mobile
              join-content-color
              text-nunito
              mt-3 is-size-7
              has-text-weight-bold
              has-text-centered
              has-text-grey"
            >
              or
            </p>

            Continue with password 
            <div
              @click="showLoginForm"
              class="
              join-margin-bottom-mobile
              is-max-desktop container
              has-text-centered
              mt-3"
              style="margin-bottom: 38px"
              v-if="showContinue"
            >
              <p
                id="signup"
                to=""
                class="Basier-small-regular link-text dark-blue-emitus join-content-color"
                >Continue with password</p>
            </div> -->

            <!-- Login form -->
            <form v-if="showLogin" class="form-login">
              <!-- user -->
              <b-field
              class="mt-5"
              label="Correo Electrónico">
                <b-input
                  placeholder="harvey@perasonspecter.com"
                  v-model="email"
                  type="email"
                >
                </b-input>
              </b-field>
              <!-- Pasword -->
              <b-field
                class="mt-5"
                label="Contraseña"
              >
                <b-input
                  type="password"
                  v-model="password" password-reveal>
                </b-input>
              </b-field>

              <!-- reset password -->
              <div class="mt-1">
                <a
                  class="text-basier"
                  style="
                  font-size: 13px;
                  color: #000;"
                  @click="resetPassword">
                    ¿Olvidaste tu contraseña?
                  <a
                    class="link-text-blue purple-light-emitus"
                    style="font-weight: bold; color: #000000;">
                    Click aquí</a>
                </a>
              </div>

              <!-- button login -->
              <div
                class="
                is-flex
                is-align-items-center
                is-justify-content-center
                pt-5">
                <button
                  class="mt-6 button-primary-medium"
                  @click.prevent="login">
                  Ingresar
                </button>
              </div>

              <!-- Go back  -->
              <div
                @click="showContinueForm"
                class="mt-5 mb-5 is-flex is-align-items-center is-justify-content-center"
              >
                <p
                  class="
                    is-text-align-center
                    text-underline text-basier-medium 
                    join-content-color
                    link-cancel-button
                    dark-gray-emitus
                    link-text-purple"
                > Cancelar </p>
              </div>
              <div style="margin-top: 59px;">
                <p
                  class="text-basier mt-4 dark-gray-emitus"
                >
                  Aún no tengo una cuenta
                  <span
                    @click="showSignupForm"
                    class="link-text-blue text-basier"
                    style="font-weight: bold; color: #48c78e; font-size: 13px;"
                  >
                    Registrarme
                  </span>
                </p>
              </div>
            </form>

            <!-- Signup Form -->
            <form v-if="showSignup" >

              <div class="name-user is-flex mt-5">
                <!-- Firstname -->
                <b-field
                  label="Nombre"
                  :message="mensajeFirst"
                  :type="messageTypeFirst"

                >
                  <b-input
                    v-on:focusout.native="ValidityFirstname"
                    v-on:focusin.native="clearValidityFirst"
                    id="firstname"
                    placeholder="Harvey"
                    v-model="firstname"
                    autocomplete="off"
                  >
                  </b-input>
                </b-field>

                <!-- Lastname -->
                <b-field
                  class="ml-2"
                  label="Apellido"
                  :message="mensajeLast"
                  :type="messageTypeLast"
                >
                  <b-input
                    v-on:focusout.native="ValidityLastname"
                    v-on:focusin.native="clearValidityLast"
                    id="lastname"
                     placeholder="Specter"
                    v-model="lastname"
                    autocomplete="off"
                  >
                  </b-input>
                </b-field>
              </div>

              <!-- eMail -->
              <b-field
                class="mt-3"
                label="Correo Electrónico"
                :message="mensajeEmail"
                :type="messageTypeEmail"
              >
                <b-input
                  v-on:focusout.native="ValidityEmail"
                  v-on:focusin.native="clearValidityEmail"
                  :validation-message= mensaje
                  id="email"
                  placeholder="harvey@personspecter.com"
                  v-model="email2"
                  autocomplete="off"
                >
                </b-input>
              </b-field>

              <!-- Password -->
              <b-field
                class="mt-5 "
                label="Contraseña"
                :message="mensajePassword"
                :type="messageTypePassword"
              >
                <b-input
                v-on:focusout.native="ValidityPassword"
                  v-on:focusin.native="clearValidityPassword"
                  password-reveal
                  id="password"
                  type="password"
                  v-model="password2"
                  autocomplete="off"
                >
                </b-input>

              </b-field>

              <!-- CTA Button -->
              <div
                class="
                  is-flex
                  is-align-items-center
                  is-justify-content-center
                  mb-6">
                <button
                  class="button-primary-medium mt-6"
                  @click.prevent="signup">
                  Registrarse
                </button>
              </div>

              <!-- Login link -->
              <div class="login-button-link">
                <p
                  class="text-basier mt-4 dark-gray-emitus"
                >
                  Ya tengo una cuenta
                  <span
                    @click="showLoginForm"
                    class="link-text-blue text-basier-medium"
                    style="color: #000;"
                  >
                    Ingresar
                  </span>
                </p>
              </div>
            </form>

            <!-- accept terms and conditions -->
            <form
              v-if="showContinue"
              class="pb-3 no-padding join-margin-bottom-mobile"
              id="danger-line"
              >
              <label class="checkbox is-flex mb-3">
                <input
                  type="checkbox" class=""
                  v-model="checkbox"
                  v-on:click="checkboxClick"
                  checked
                  required
                />
                <div>

                  <p
                    id="checkboxTerms"
                    class="join-content-color ml-2 mt-4 has-text-centered dark-gray-emitus"
                    style="color: #000000; width: 200px; font-size: 13px"
                  >
                    Acepto los
                    <router-link
                      target="_blank"
                      to="/terms"
                      class="link-text join-content-color dark-blue-emitus"
                      >términoss de uso
                    </router-link>
                    y
                    <router-link
                      target="_blank"
                      to="/privacy"
                      class="link-text join-content-color dark-blue-emitus"
                      >política de privacidad.</router-link
                    >
                  </p>
                </div>
              </label>
            </form>

          </div>
        </div>
      </div>
    </div>
    <!-- loader -->
    <div v-if="loader">
      <Loading/>
    </div>

  </div>

</template>

<script>
import Loading from '@/components/modals/loader.vue'
import firebaseConfig from '@/firebase/init.js'
import firebase from 'firebase/app'
import 'firebase/auth'
import UserService from '@/services/UserService'
import dayjs from 'dayjs'
import MixpanelService from '@/services/MixpanelService'
import mixpanel from 'mixpanel-browser'

var utc = require('dayjs/plugin/utc')
var timezone = require('dayjs/plugin/timezone')

dayjs.extend(utc)
dayjs.extend(timezone)

export default {
  name: 'ContinueModal',
  components: {
    Loading
  },
  data: () => {
    return {
      authUser: null,
      checkbox: true,
      showContinue: true,
      showLogin: false,
      showSignup: false,
      email: '',
      password: '',
      user: {},

      firstname: '',
      lastname: '',
      email2: '',
      password2: '',
      mensajeEmail: '',
      mensajePassword: '',
      mensajeFirst: '',
      mensajeLast: '',
      messageTypeEmail: '',
      messageTypePassword: '',
      messageTypeFirst: '',
      messageTypeLast: '',
      valid: true,
      mensaje: '',
      passwordError: null,
      generalError: null,
      loader: false
    }
  },
  created () {
    firebase.auth().onAuthStateChanged(user => {
      this.authUser = user
      // console.log('--------> uyu', this.authUser)
    })
  },
  methods: {
    // Accept terms & conditions
    checkboxClick (e) {
      this.checkbox = e.target.checked
      if (this.checkbox === true) {
        document.getElementById('checkboxTerms').classList.remove('validationCheck')
      }
    },

    // Signin with Google -------
    async signInWithGoogle () {
      if (this.checkbox === true) {
        this.loader = true
        const timezone = dayjs.tz.guess()
        const provider = new firebase.auth.GoogleAuthProvider()
        await firebase.auth().signInWithPopup(provider)
          .catch(err => {
            this.loader = false
            this.danger(err.message)
          })

        const idToken = await firebase.auth().currentUser.getIdToken(true)

        const newUser = await UserService.ContinueWith(
          idToken,
          this.authUser.displayName,
          this.authUser.email,
          this.authUser.uid,
          timezone
        )

        if (newUser.status === 201) {
          this.loader = false
          console.log('11', newUser)
          MixpanelService.signUp(newUser)
          this.notify.$emit('updateData')
          this.closeModal()
        } else if (newUser.status === 200) {
          console.log('00', newUser)
          // mixpanel.identify(`${newUser.data._id}`)
          MixpanelService.login(newUser.data)
          this.notify.$emit('updateData')
          this.loader = false
          this.closeModal()
        } else {
          console.log('55')
          this.danger('Hubo un problema de autenticación')
        }

        // this.notify.$emit('updateData')
        // this.loader = false
        // this.closeModal()
        // location.reload()
      } else {
        document.getElementById('checkboxTerms').classList.add('validationCheck')
        this.danger('Debes aceptar lso Términos y Política de Privacidad para continuar')
      }
    },

    // Signin with Apple -------
    async signInWithApple () {
      if (this.checkbox === true) {
        const timezone = dayjs.tz.guess()
        const provider = new firebase.auth.OAuthProvider('apple.com')
        await firebase.auth().signInWithPopup(provider)
          .catch(err => {
            this.danger(err.message)
          })

        const idToken = await firebase.auth().currentUser.getIdToken(true)

        let email = this.authUser.email
        const domainEmail = email.substring(email.indexOf('@') + 1)

        if (domainEmail === 'privaterelay.appleid.com') {
          email = ''
          // console.log('---->>> Salio')
        }

        const newUser = await UserService.ContinueWith(
          idToken,
          this.authUser.displayName,
          email,
          this.authUser.uid,
          timezone
        )

        if (newUser.status === 201) {
          this.loader = false
          console.log('11', newUser)
          MixpanelService.signUp(newUser)
          this.notify.$emit('updateData')
          this.closeModal()
        } else if (newUser.status === 200) {
          console.log('00', newUser)
          // mixpanel.identify(`${newUser.data._id}`)
          MixpanelService.login(newUser.data)
          this.notify.$emit('updateData')
          this.loader = false
          this.closeModal()
        } else {
          console.log('55')
          this.danger('Hubo un problema de autenticación')
        }

        // this.notify.$emit('updateData')
        // this.closeModal()
        // location.reload()
      } else {
        document.getElementById('checkboxTerms').classList.add('validationCheck')
        this.danger('Debes aceptar lso Términos y Política de Privacidad para continuar')
      }
    },

    // Signin with Faceebook -------
    async signInWithFacebook () {
      if (this.checkbox === true) {
        const timezone = dayjs.tz.guess()
        const provider = new firebase.auth.FacebookAuthProvider()
        provider.addScope('email')

        await firebase.auth().signInWithPopup(provider)
          .catch(err => {
            this.danger(err.message)
          })

        const idToken = await firebase.auth().currentUser.getIdToken(true)

        const newUser = await UserService.ContinueWith(
          idToken,
          this.authUser.displayName,
          this.authUser.email,
          this.authUser.uid,
          timezone
        )

        if (newUser.status === 201) {
          this.loader = false
          console.log('11', newUser)
          MixpanelService.signUp(newUser)
          this.notify.$emit('updateData')
          this.closeModal()
        } else if (newUser.status === 200) {
          console.log('00', newUser)
          // mixpanel.identify(`${newUser.data._id}`)
          MixpanelService.login(newUser.data)
          this.notify.$emit('updateData')
          this.loader = false
          this.closeModal()
        } else {
          console.log('55')
          this.danger('Hubo un problema de autenticación')
        }

        // this.notify.$emit('updateData')
        // this.closeModal()
        // location.reload()
      } else {
        document.getElementById('checkboxTerms').classList.add('validationCheck')
        this.danger('Debes aceptar lso Términos y Política de Privacidad para continuar')
      }
    },

    continueSignup () {
      if (this.checkbox === true) {
        this.$router.push('/signup')
      } else {
        document.getElementById('checkboxTerms').classList.add('validationCheck')
        this.danger('Debes aceptar lso Términos y Política de Privacidad para continuar')
      }
    },

    // Danger Toast
    danger (msg) {
      this.$buefy.toast.open({
        duration: 4000,
        message: msg,
        position: 'is-top',
        type: 'is-danger'
      })
    },

    // Success Toast
    success (msg) {
      this.$buefy.toast.open({
        duration: 4000,
        message: msg,
        position: 'is-top',
        type: 'is-success'
      })
    },

    // Close modal
    closeModal () {
      this.$emit('close')
    },

    // Login with Password --------
    async login () {
      const timezone = dayjs.tz.guess()
      await firebase.auth().signInWithEmailAndPassword(this.email, this.password)
        .catch(err => {
          this.danger(err)
        })

      await firebase.auth().currentUser.getIdToken(true)
        .then(async idToken => {
          await UserService.SigninFirebase(idToken, timezone)
            .then(response => {
              if (response.status === 200) {
                console.log('00', response)
                // mixpanel.identify(`${response.data._id}`)
                MixpanelService.login(response.data)
                this.notify.$emit('updateData')
              }
            })
          await UserService.getCurrentUser(idToken)
            .then(user => {
              this.user = user.data
            })
          this.notify.$emit('updateData')
          this.closeModal()
          // location.reload()
        })
    },

    // Reset Password -------
    async resetPassword () {
      await firebase.auth().sendPasswordResetEmail(this.email)
        .then(() => {
          this.success('Check your inbox')
        })
        .catch((error) => {
          const errorMessage = error.message
          this.danger(errorMessage)
        })
    },

    // Show Continue
    showContinueForm () {
      this.showContinue = true
      this.showLogin = false
      this.showSignup = false
    },

    // Show login
    showLoginForm () {
      this.showContinue = false
      this.showLogin = true
      this.showSignup = false
    },

    // Show signup
    showSignupForm () {
      this.showContinue = false
      this.showLogin = false
      this.showSignup = true
    },

    // Sign Up with Password --------
    async signup () {
      const timezone = dayjs.tz.guess()
      if (this.firstname && this.lastname && this.email2 && this.password2) {
        this.valid = true
      }

      this.ValidityFirstname()
      this.ValidityLastname()
      this.ValidityEmail()
      this.ValidityPassword()

      if (this.valid) {
        await firebase.auth().createUserWithEmailAndPassword(this.email2, this.password2)
          .catch(err => {
            this.generalError = err.message
            this.danger(this.generalError)
          })

        const newUser = await UserService.SignupFirebase(
          this.firstname,
          this.lastname,
          this.authUser.email,
          this.authUser.uid,
          timezone
        )
        // console.log('7788 newUser', newUser)
        if (newUser.status === 201) {
          // console.log('99', newUser)
          MixpanelService.signUp(newUser)
          this.notify.$emit('updateData')
        }
      }
    },

    // Validity Firstname / Lastname
    ValidityFirstname () {
      if (!this.firstname) {
        this.mensajeFirst = 'Cuál es tu nombre'
        this.messageTypeFirst = 'is-primary'
        this.valid = false
      } else {
        this.mensajeFirst = ''
        // this.messageTypeFirst = 'is-success'
      }
    },
    ValidityLastname () {
      if (!this.lastname) {
        this.mensajeLast = 'Cuál es tu apellido'
        this.messageTypeLast = 'is-primary'
        this.valid = false
      } else {
        this.mensajeLast = ''
        // this.messageTypeLast = 'is-success'
      }
    },

    // Validity email
    async ValidityEmail () {
      try {
        const response = await firebase.auth().fetchSignInMethodsForEmail(this.email2)
        if (response.length > 0) {
          this.mensajeEmail = 'Este correo ya está registrado'
          this.messageTypeEmail = 'is-primary'
          this.valid = false
        } else if (!this.validEmail(this.email2)) {
          this.mensajeEmail = 'Algo está mal. Revisa to correo electrónico.'
          this.messageTypeEmail = 'is-primary'
          this.valid = false
        } else {
          this.mensajeEmail = ''
          // this.messageTypeEmail = 'is-success'
        }
      } catch (error) {
        this.mensajeEmail = error.message
        this.messageTypeEmail = 'is-primary'
        this.valid = false
      }
    },

    // Validity Password
    ValidityPassword () {
      if (this.password2.length < 6 || this.password2 === '') {
        this.mensajePassword = 'La contraseña debe tener al menos 6 caracteres'
        this.messageTypePassword = 'is-primary'
        this.valid = false
      } else {
        this.mensajePassword = ''
        this.messageTypePassword = ''
        // this.messageTypePassword = 'is-success'
      }
    },
    validEmail: function (email) {
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      return re.test(email)
    },

    // Clear validities
    clearValidityFirst () {
      this.messageTypeFirst = ''
      this.mensajeFirst = ''
    },
    clearValidityLast () {
      this.messageTypeLast = ''
      this.mensajeLast = ''
    },
    clearValidityEmail () {
      this.messageTypeEmail = ''
      this.mensajeEmail = ''
    },
    clearValidityPassword () {
      this.mensajePassword = ''
      this.mensajePassword = ''
    },

    // Take me back or home
    takeMeBack () {
      if (this.$router.history._startLocation === this.$router.history.current.fullPath) {
        this.$router.push('/')
      } else {
        history.go(-1)
      }
    }

  }
}
</script>

<style scoped>
/* #sign_up */

#sign_up input:-webkit-autofill,
#sign_up input:-webkit-autofill:hover,
#sign_up input:-webkit-autofill:active,
#sign_up input:-webkit-autofill:focus {
    background-color: #FFFFFF !important;
    color: #555 !important;
    -webkit-box-shadow: 0 0 0 1000px white inset !important;
    -webkit-text-fill-color: #555555 !important;
}
#sign_up .has_required {
  text-decoration: line-through;
  color:#6e3dea;
}
#sign_up form {
    margin-top: 30px;
    width: 90%;
    margin: auto;
    max-width: 360px;
}

#sign_up .title-header-section-create-account {
  margin-top: 35px;
  margin-bottom: 45px;
  margin-left: 5%;
}
#sign_up .container-content-create-account {
  width: 85%;
}

#sign_up input, #sign_up select {
    height: 45px !important;
}

#sign_up input::placeholder {
  font-family: 'Nunito' !important;
  color: #d2d2d2 !important;
}

#sign_up input:hover {
  border-color: #EEEEEE;
}

.validationCheck {
  border-bottom: 1px solid red;
  padding-bottom: 10px;
}
.buttons-joins {
  margin-top: 34px !important;
}

.signup-with-button {
  margin-top: 10px !important;
}

.link-text {
  text-decoration: underline;
  padding-bottom: 4px;
  transition: color 200ms ease;
}

.link-text:hover, .link-text:active, .link-text:focus {
   color:  rgba(13, 18, 57, 0.6)!important;
}

.signup-with-button {
  height: 45px !important;
  border-radius: 0px !important;
  /* margin-top: 15px; */
}

.big-button {
  width: 220px !important;
}

#danger-line {
  border-radius: 10px;
  padding: 1px;
  margin-top: 25px;
}
#danger {
  color: red;
  font-size: 12px;
  margin-bottom: 10px;
}
.paragraph-short {
  max-width: 197px;
  margin: auto;
}
.join-title {
  font-size: 28.5px;
}
.shadow-btn {
    border: 1px solid #000000;
    /*box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.08)!important;*/
}

.align-content-center {
  align-content: center;
  max-width: 320px;
}

.login-button-link {
  margin-top: 30px;
  text-align: center;
}
</style>
